import './App.css'
import "leaflet/dist/leaflet.css";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { RouterProvider } from 'react-router-dom'
import { router } from './Router'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme/theme'
import { Suspense } from 'react';
import Loader from './components/Loader/Loader';
import { CustomProvider } from 'rsuite';
import frFR from 'rsuite/locales/fr_FR';

export const CURRENT_ENV = import.meta.env.VITE_APP_ENV || 'dev'
export const CURRENT_SHA = import.meta.env.VITE_APP_SHA || 'unknown'

function App() {
  const queryClient = new QueryClient()

  console.log(`🔧 Booting POUET on ${CURRENT_ENV} environment, v:${CURRENT_SHA}`)

  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <CustomProvider locale={frFR}>
            <RouterProvider router={router(queryClient)} />
            <ReactQueryDevtools initialIsOpen={false} />
          </CustomProvider>
        </QueryClientProvider>
      </Suspense>
    </ChakraProvider>
  )
}

export default App
