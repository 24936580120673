
import { deleteMission, getOrganizationMissions, getOrganizationMissionsKey } from '@/api/mission';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { MissionAttributes } from '@/interfaces/mission';
import { SortingState } from '@tanstack/react-table';
import { MissionTable } from '@/screens/MissionTable/MissionTable';
import { useMemo, useState } from 'react';
import { parseMissionQueryParams } from '@/utils/missions';
import { VStack } from '@chakra-ui/react';
import { MissionListFilters } from './MissionListFilters';
import QueryString from 'qs';
import { missionsRoute } from '@/constants/routes';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';


export const MissionsList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => parseMissionQueryParams(searchParams), [searchParams])
  const currentPage = queryParams.page ? parseInt(queryParams.page) : 1

  const [sorting, setSorting] =
    useState<SortingState>([{desc: true, id: 'begin_at'}])

  const queryKey = [getOrganizationMissionsKey, orgId, structureId, sorting, queryParams]
  const query = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationMissions(orgId, currentPage, {structure: [structureId], ...queryParams}, sorting) : null
  )

  const deleteMissionMutation = useOptimisticDeleteMutation<ApiResourceResponse<MissionAttributes>, RecordId>(queryClient, deleteMission, queryKey)

  const onDelete = async (missionId: RecordId) => {
    console.log("Delete !", { missionId })
    deleteMissionMutation.mutate(missionId)
  }

  const onPaginationChange = (page: number)  => {
    console.log("Pagination change: ", { page })
    navigate(missionsRoute(orgId) + '?' + QueryString.stringify({ ...queryParams, page }))
  }

  return (
    <VStack spacing={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <MissionListFilters params={queryParams} />
      <RemoteFetchContainer
        {...query}
        render={(data) => (data ? <MissionTable
          onDelete={onDelete}
          onPaginationChange={onPaginationChange}
          onSortingChange={setSorting}
          data={data}
          sorting={sorting}
        /> : <></>)}
      >

      </RemoteFetchContainer>
    </VStack>
  );
}
